<script setup lang="ts">
import { getTranslatedProperty } from "@shopware-pwa/helpers-next";

const { apiInstance } = useShopwareContext();

import { useListing } from "@shopware-pwa/composables-next";

const { category } = useCategory();
const route = useRoute();
const router = useRouter();

const { getCurrentSortingOrder, changeCurrentSortingOrder, getSortingOrders, getTotal } = useListing({
  listingType: "categoryListing",
  defaultSearchCriteria: {
    limit: 24,
  }
});

const categoryId = category.value?.id;

const { data: totalCount } = await useAsyncData('totalCount' + categoryId, async () => {
  const response = await apiInstance.invoke.post("/store-api/product-listing/" + categoryId, {
    "total-count-mode": "1",
  });

  return response?.data?.total ?? 0;
});
import { ShopwareSearchParams } from "@shopware-pwa/types";

const currentSortingOrder = computed({
  get: (): string => getCurrentSortingOrder.value || "",
  set: async (order: string): Promise<void> => {
    await router.push({
      query: {
        ...route.query,
        order,
      },
    });

    changeCurrentSortingOrder(order, <Partial<ShopwareSearchParams>>route.query);
  },
});
</script>

<template>
  <div class="flex gap-4 justify-between flex-wrap sm:items-end">
    <div class="flex flex-wrap items-end gap-x-8 gap-y-4">
      <h1 class="text-maas-typography-headline-primary text-5xl">{{ getTranslatedProperty(category, "name") }}</h1>
      <div class="text-maas-typography-text-grey-light mb-[5px] text-sm">({{ getTotal }} Produkte)</div>
    </div>

    <SharedFormSelect v-if="totalCount && totalCount > 0" id="sorting" class="w-50" v-model="currentSortingOrder" :elems="getSortingOrders" />
  </div>
</template>
